import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Select,
  Grid,
  Button,
  Popup,
  Input,
  Label,
  Container,
  Transition
} from "semantic-ui-react";
import DataTable from "react-data-table-component";
import Egon from "./Egon";
import {
  APIgetRetriveLogistictsProducts,
  APIgetRetriveWarehouse,
  APIgetRetriveSingleWarehouse,
  APIpostOrdersLogistics,
} from "./apicalls";
import { getCluster } from "./services/utils";

const Panel = ({ workspace, pageConfiguration }) => {
  const [data, setData] = useState([]);
  const [objSend, setObjSend] = useState({});
  const [dataSelectA, setDataSelectA] = useState([]);
  const [dataSelectB, setDataSelectB] = useState([]);
  const [valueDDa, setValueDDa] = useState("");
  const [valueDDb, setValueDDb] = useState("");
  const [dataWarehouse, setDataWarehouse] = useState({});
  const [objTableWarehouse, setObjTableWarehouse] = useState({});
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [presso, setPresso] = useState("");
  const [ragSoc, setRagSoc] = useState("");
  const [msg, setMsg] = useState("");
  const [notes, setNotes] = useState("")
  const [colorMsg, setColorMsg] = useState("")
  const [isMsgVisible, setIsMsgVisible] = useState(false);
  const [filterText, setFilterText] = React.useState('')

  const cluster = React.useMemo(() => getCluster(pageConfiguration.extraConfigs), [pageConfiguration])

  const refreshDataTable = useCallback(async () => {
    setData([]);
    setObjSend({});
    setObjSend({});
    setValueDDa("");
    setValueDDb("");
    setDataWarehouse({});
    setObjTableWarehouse({});
    setTelefono("");
    setEmail("")     
    setNotes("")
    setPresso("");
    setRagSoc("");
    setColorMsg("green");

    const d = await APIgetRetriveLogistictsProducts(cluster, workspace.id);
    setData(d);
  }, [cluster, workspace.id]);

  useEffect(() => {
    if (msg.length > 0) {
      setIsMsgVisible(true);

      const timeoutId = setTimeout(() => {
        setIsMsgVisible(false);
        setMsg("");
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [msg]);

  const getProductsStock = useCallback(async (wsId) => {
    const d = await APIgetRetriveLogistictsProducts(cluster, wsId);
    setData(d);
  }, [cluster]);

  useEffect(() => {
    getProductsStock(workspace.id)
  }, [getProductsStock, workspace.id]);

  const clearData = useCallback((par) => {
    par();
  }, []);

  const getDropdownsData = useCallback(async () => {
    try {
      const warehouseItems = await APIgetRetriveWarehouse(cluster);

      const dataWarehouseClean = warehouseItems.map((x) => {
        if (x.codice) {
          return { text: `${x.codice} - ${x.ragSoc}`, value: x.codice };
        } else {
          return { text: x.ragSoc, value: x.codice };
        }

      });

      setDataSelectB(dataWarehouseClean);
    } catch (error) {
      console.log(error);
    }

    const d = workspace?.enumConfig?.inputs?.sottocodiceProgetto?.values;
    const dataSpClean = d.map((x) => {
      delete x.label;
      return { ...x, text: x.value };
    });

    setDataSelectA(dataSpClean || []);
  }, [cluster, workspace?.enumConfig?.inputs?.sottocodiceProgetto?.values]);

  useEffect(() => {
    getDropdownsData()
  }, [getDropdownsData])

  const onDropdownAChange = useCallback(
    (event, data) => {
      setValueDDa(data.value);
      setObjSend({ ...objSend, ...{ sottocodiceProgetto: data.value } });
    },
    [objSend]
  );

  const onDropdownBChange = useCallback(
    async (event, data) => {
      const r = await APIgetRetriveSingleWarehouse(cluster, data.value);
      setDataWarehouse(r);
      setValueDDb(data.value);
      setTelefono(r?.telefono || "")
      setEmail(r?.email || "")
      setNotes(r?.notes || "")
      setPresso(r?.presso || "")
      setObjSend({
        ...objSend,
        ...{ magazzinoDestinazione: data.value, telefono: r?.telefono || "", email: r?.email || "", presso: r?.presso, region: r?.regione, notes: r?.notes },
      });
    },
    [cluster, objSend]
  );

  const onInputQuantityChange = useCallback(
    (e, codArticolo) => {

      const updatedObjTableWarehouse = {
        ...objTableWarehouse,
        [codArticolo]: e.target.value || 0,
      };

      if (e.target.value) {
        updatedObjTableWarehouse[codArticolo] = e.target.value
      } else {
        delete updatedObjTableWarehouse[codArticolo]
      }

      const res = Object.keys(updatedObjTableWarehouse).map((x, index) => {
        return {
          qta: parseInt(Object.values(updatedObjTableWarehouse)[index], 10),
          codArticolo: x,
        };
      });

      setObjSend({ ...objSend, articoli: res });
      setObjTableWarehouse(updatedObjTableWarehouse);
    },
    [objTableWarehouse, objSend]
  );

  const getActions = useCallback(
    (row) => {
      return (
        <Popup
          content={"inserisci la quantità"}
          trigger={
            <Input
              type="number"
              min={0}
              value={objTableWarehouse[row.codArticolo] ?? ''}
              onChange={(e) => onInputQuantityChange(e, row.codArticolo)}
            />
          }
        />
      );
    },
    [onInputQuantityChange, objTableWarehouse]
  );

  const onInputChange = useCallback(
    (event, par) => {
      if (par === "presso") {
        setPresso(event.target.value);
      } else if (par === "telefono") { 
        setTelefono(event.target.value);
      } else if (par === "ragSoc") {
        setRagSoc(event.target.value);
      } else if (par === "email") {
        setEmail(event.target.value);
      }else if (par === "notes") {
        setNotes  (event.target.value);
      }

      setObjSend({ ...objSend, ...{ [par]: event.target.value } });
    },
    [objSend]
  );

  const generateOrder = useCallback(async () => {
    let r = null;

    try {
      r = await APIpostOrdersLogistics(cluster, workspace.id, objSend);

      if (r) {
        if (r?.data?.errors?.[0]?.message) {
          setColorMsg("red");
          setMsg(r?.data?.errors?.[0]?.message);
        } else {
          refreshDataTable();

          setMsg("INVIO COMPLETATO");
        }
      }
    } catch {
      setColorMsg("red");
      setMsg("API KO");
    }
  }, [cluster, objSend, workspace.id, refreshDataTable]);

  const columns = useMemo(() => {
    return [
      {
        id: "DESCRIZIONE ARTICOLO",
        name: "DESCRIZIONE ARTICOLO",
        selector: (row, index) => row.descrArticolo,
        cell: (row) => row.descrArticolo,
        center: true,
        minwidth: "150px",
        width: "35%",
        sortable: true,
        compact: true,
      },
      {
        id: "CODICE ARTICOLO",
        name: "CODICE ARTICOLO",
        selector: (row, index) => row.codArticolo,
        cell: (row) => row.codArticolo,
        center: true,
        minwidth: "150px",
        width: "35%",
        sortable: true,
        compact: true,
      },
      {
        id: "QUANTITA",
        name: "QUANTITA",
        selector: (row, index) => row.qta,
        cell: (row) => row.qta,
        center: true,
        minwidth: "150px",
        width: "15%",
        sortable: true,
        compact: true,
      },
      {
        id: "ACTIONS",
        name: "",
        selector: (row, index) => row.id,
        cell: (row) => getActions(row),
        center: true,
        minwidth: "150px",
        width: "15%",
        sortable: true,
        compact: true,
      },
    ];
  }, [getActions]);

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#1c2545",
        color: "white",
        textTransform: "uppercase",
        whiteSpace: "normal!important",
        textOverflow: "unset!important",
        textAlign: "center!important",
      },
    },
    subHeader: {
      style: {
        paddingLeft: '0px!important',
        paddingRight: '0px!important'
      },
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (

      <Container fluid>
        <Input
          onChange={e => setFilterText(e.target.value)}
          icon="search"
          label='Cerca Codice o Descrizione Articolo'
          value={filterText}
          fluid
        />
      </Container>
    )

  }, [filterText, setFilterText])

  return (
    <Grid>
      <Grid.Row>
        <b style={{ marginLeft: "5px" }}>Articoli:</b>
      </Grid.Row>
      <Grid.Row
        style={{
          border: "2px solid lightgrey",
          borderRadius: "5px",
          borderTop: "none",
          borderLeft: "none",
          margin: "5px",
        }}
      >
        <Grid.Column width={16}>
          <DataTable
            columns={columns}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            pagination
            data={
              data.filter(data => ((data.descrArticolo?.toLowerCase().includes(filterText.toLowerCase())) || (data.codArticolo?.toLowerCase().includes(filterText.toLowerCase()))))
            }
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
            keyField="id"
            customStyles={customStyles}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <b style={{ marginLeft: "5px" }}>
          Indirizzo:{" "}
          {objSend?.magazzinoDestinazione
            ? "Dati relativi al magazzino selezionato"
            : ""}
        </b>
      </Grid.Row>
      <Grid.Row
        style={{
          border: "2px solid lightgrey",
          borderRadius: "5px",
          borderTop: "none",
          borderLeft: "none",
          margin: "5px",
        }}
      >
        <Container style={{ width: "95%" }}>
          {(objSend?.magazzinoDestinazione && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Label>Provincia</Label>
                  <Input disabled fluid value={dataWarehouse.pr} />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Label>Città</Label>
                  <Input disabled fluid value={dataWarehouse.citta} />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Label>Cap</Label>
                  <Input disabled fluid value={dataWarehouse.cap} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Label>Indirizzo</Label>
                  <Input disabled fluid value={dataWarehouse.indir} />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Label>Regione</Label>
                  <Input disabled fluid value={dataWarehouse.regione} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )) || (
              <Egon
                refreshDataTable={refreshDataTable}
                setObjSend={setObjSend}
                objSend={objSend}
                clearData={clearData}
              />
            )}
        </Container>
      </Grid.Row>

      <Grid.Row>
        <b style={{ marginLeft: "5px" }}>Riferimenti:</b>
      </Grid.Row>
      <Grid.Row
        style={{
          border: "2px solid lightgrey",
          borderRadius: "5px",
          borderTop: "none",
          borderLeft: "none",
          margin: "5px",
        }}
      >
        <Container style={{ width: "95%" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Label>Ragione Sociale *</Label>
                <Input
                  fluid
                  value={dataWarehouse?.ragSoc ? dataWarehouse?.ragSoc : ragSoc}
                  disabled={Boolean(dataWarehouse?.ragSoc)}
                  placeholder=""
                  onChange={(event) => onInputChange(event, "ragSoc")}
                />
              </Grid.Column>

              <Grid.Column width={4}>
                <Label>Telefono *</Label>
                <Input
                  fluid
                  value={telefono}
                  placeholder="+39"
                  disabled={Boolean(dataWarehouse?.telefono)}
                  onChange={(event) => onInputChange(event, "telefono")}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Label>Email</Label>
                <Input
                  fluid
                  value={email}
                  placeholder="@domain.it"
                  disabled={Boolean(dataWarehouse?.email)}
                  onChange={(event) => onInputChange(event, "email")}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Label>Presso</Label>
                <Input
                  value={presso}
                  fluid
                  disabled={Boolean(dataWarehouse?.presso)}
                  onChange={(event) => onInputChange(event, "presso")}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Grid.Row>

      <Grid.Row>
        <b style={{ marginLeft: "5px" }}>Extra data:</b>
      </Grid.Row>
      <Grid.Row
        style={{
          border: "2px solid lightgrey",
          borderRadius: "5px",
          borderTop: "none",
          borderLeft: "none",
          margin: "5px",
        }}
      >
        <Container style={{ width: "95%" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Label>Sottoprogetto *</Label>
                <Select
                  fluid
                  onChange={(event, data) => onDropdownAChange(event, data)}
                  options={dataSelectA}
                  value={valueDDa}
                />
              </Grid.Column>

              <Grid.Column width={8}>
                <Label>Magazzino</Label>
                <Select
                  fluid
                  onChange={(event, data) => onDropdownBChange(event, data)}
                  options={dataSelectB}
                  value={valueDDb}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row >
             <Grid.Column>
                <Label>Note</Label>
                <Input
                  type="text"
                  fluid
                  onChange={(event) => onInputChange(event, "notes")}
                  value={notes}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Grid.Row>


      {msg && (
        <Transition visible={isMsgVisible} animation="swing down" duration={800}>
          <Grid.Row
            style={{
              backgroundColor: colorMsg,
              position: "fixed",
              right: "0px",
              top: "68px",
              zIndex: 999
            }}
          >
            <Grid.Column
              width={16}
              style={{
                border: `3px solid ${colorMsg}`,
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <p style={{ textAlign: "center", color: "white" }}>
                <b>{msg.toUpperCase()}</b>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Transition>
      )}

      <Grid.Row
        style={{
          margin: "5px",
        }}
      >
        <Grid.Column width={5}></Grid.Column>

        <Grid.Column width={6}>
          <Button
            onClick={() => generateOrder()}
            positive
            fluid
            size="big"
            content="GENERA ORDINE"
            icon="dolly"
          />
        </Grid.Column>

        <Grid.Column width={5}></Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Panel;
